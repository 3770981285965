/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body, menu,router-outlet,mat-toolbar-row{
	padding: 0px;
    margin: 0px;
}

mat-toolbar{
	position: fixed;
	top:0px;
	left:0px;
	z-index: 997 !important;
    justify-content: space-between;
}

.txtFinder{
	position:relative;
	top:0px;width:74%;
	margin: 0% 2%;padding: 0px;
	margin: 0% 10%;
}

.txtFinder .mat-input-underline{
	box-shadow: 0px -1.875rem rgba(0, 0, 0, 0.12);
}
.txtFinder .mat-input-ripple{
	box-shadow: 0px -1.875rem #0277bd;
}

/*--------------------------------Listas-------------------------------------*/

h3{
	width: 100%;
	text-align: center !important;
}
	mat-list-item .mat-list-text, mat-list-item .mat-list-text a, mat-list-item a{
		height: 100%;
	}

	.mat-list-item-content{
		width: 100% !important;
	}

  .mat-list-item-content > span{
	padding:3px;
	background:black;
	color:white;
	width: auto !important;
	border-radius: 42px 42px 42px 42px;
	-moz-border-radius: 42px 42px 42px 42px;
	-webkit-border-radius: 42px 42px 42px 42px;
	border: 0px solid #000000;
  }
  mat-option img, mat-expansion-panel-header img{
	height:30px;
	width:30px;
	border-radius:15px;
	-moz-border-radius:15px;
	-webkit-border-radius:15px;
  }
  mat-expansion-panel-header{
	display: flex;
	justify-content: space-between;
  }